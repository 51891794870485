import 'core-js'
import cssVars from 'css-vars-ponyfill'

import 'intersection-observer'

import smoothscroll from 'smoothscroll-polyfill'

// eslint-disable-next-line
smoothscroll.polyfill()
const linkElms = document.querySelectorAll('a.page_link')
linkElms.forEach((elm) => {
  elm.addEventListener('click', (e) => {
    const target = <HTMLLinkElement>elm
    const pattern = /#.+/
    if (/#top/.test(target.href)) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      return false
    }
    if (!pattern.test(target.href)) return
    e.preventDefault()
    const targetUrl: URL = new URL(target.href)
    const targetId: string = targetUrl.hash
    document.querySelector(targetId)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  })
})

const observe = () => {
  // 監視したい要素をobserve
  const targets: NodeListOf<Element> = document.querySelectorAll(
    '.animate__animated'
  )
  targets.forEach((target) => {
    //監視したい対象をobserve
    observer.observe(target)
  })
}

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const dataIndex = entry.target.getAttribute('data-index')
        if (Number(dataIndex) === -1) {
          entry.target.classList.add('fadeInCustome')
        } else {
          if (dataIndex !== null && Number(dataIndex) > 0) {
            entry.target.classList.add('animate__fadeIn')
          } else {
            entry.target.classList.add('animate__fadeInUp')
          }
        }
      }
    })
  },
  {
    rootMargin: '-20% 0px -20% 0px',
  }
)
observe()

document.addEventListener(
  'wpcf7mailsent',
  function (event) {
    location.href = 'https://3-line.jp/send/'
  },
  false
)
