import 'core-js'

import 'alpinejs/dist/alpine-ie11'
// import 'alpinejs'
declare global {
  interface Window {
    header: any
    splash: any
    video: any
  }
}
window.splash = () => {
  return {
    isSplash: true,
    enterSplash: false,
    waitTime: async function () {
      await new Promise((resolve) => setTimeout(resolve, 0))
      this.enterSplash = true
      await new Promise((resolve) => setTimeout(resolve, 500))
      this.isSplash = false
    },
  }
}

window.header = () => {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const windowWidth = window.innerWidth

  const breakPoint = 1024

  const isPcSize = windowWidth >= breakPoint
  let open = false
  if (isPcSize) {
    open = true
  }
  return {
    open: open,
    close() {
      this.open = false
    },
  }
}

window.video = () => {
  const windowWidth = window.innerWidth

  const breakPoint = 1024
  const isPcSize = windowWidth >= breakPoint

  return {
    mute: true,
    isPcSize,
  }
}
